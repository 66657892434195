@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-Bold.eot'),
  url('../fonts/TTNorms-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-Bold.woff2') format('woff2'),
  url('../fonts/TTNorms-Bold.woff') format('font'),
  url('../fonts/TTNorms-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-ThinItalic.eot'),
  url('../fonts/TTNorms-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-ThinItalic.woff2') format('woff2'),
  url('../fonts/TTNorms-ThinItalic.woff') format('font'),
  url('../fonts/TTNorms-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-Thin.eot'),
  url('../fonts/TTNorms-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-Thin.woff2') format('woff2'),
  url('../fonts/TTNorms-Thin.woff') format('font'),
  url('../fonts/TTNorms-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-LightItalic.eot'),
  url('../fonts/TTNorms-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-LightItalic.woff2') format('woff2'),
  url('../fonts/TTNorms-LightItalic.woff') format('font'),
  url('../fonts/TTNorms-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-ExtraBoldItalic.eot'),
  url('../fonts/TTNorms-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/TTNorms-ExtraBoldItalic.woff') format('font'),
  url('../fonts/TTNorms-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-Italic.eot'),
  url('../fonts/TTNorms-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-Italic.woff2') format('woff2'),
  url('../fonts/TTNorms-Italic.woff') format('font'),
  url('../fonts/TTNorms-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-ExtraLight.eot'),
  url('../fonts/TTNorms-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-ExtraLight.woff2') format('woff2'),
  url('../fonts/TTNorms-ExtraLight.woff') format('font'),
  url('../fonts/TTNorms-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-HeavyItalic.eot'),
  url('../fonts/TTNorms-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-HeavyItalic.woff2') format('woff2'),
  url('../fonts/TTNorms-HeavyItalic.woff') format('font'),
  url('../fonts/TTNorms-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-MediumItalic.eot'),
  url('../fonts/TTNorms-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-MediumItalic.woff2') format('woff2'),
  url('../fonts/TTNorms-MediumItalic.woff') format('font'),
  url('../fonts/TTNorms-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-BoldItalic.eot'),
  url('../fonts/TTNorms-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-BoldItalic.woff2') format('woff2'),
  url('../fonts/TTNorms-BoldItalic.woff') format('font'),
  url('../fonts/TTNorms-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-Heavy.eot'),
  url('../fonts/TTNorms-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-Heavy.woff2') format('woff2'),
  url('../fonts/TTNorms-Heavy.woff') format('font'),
  url('../fonts/TTNorms-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-Light.eot'),
  url('../fonts/TTNorms-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-Light.woff2') format('woff2'),
  url('../fonts/TTNorms-Light.woff') format('font'),
  url('../fonts/TTNorms-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-Medium.eot'),
  url('../fonts/TTNorms-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-Medium.woff2') format('woff2'),
  url('../fonts/TTNorms-Medium.woff') format('font'),
  url('../fonts/TTNorms-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-ExtraBold.eot'),
  url('../fonts/TTNorms-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-ExtraBold.woff2') format('woff2'),
  url('../fonts/TTNorms-ExtraBold.woff') format('font'),
  url('../fonts/TTNorms-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-ExtraLightItalic.eot'),
  url('../fonts/TTNorms-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/TTNorms-ExtraLightItalic.woff') format('font'),
  url('../fonts/TTNorms-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-Regular.eot'),
  url('../fonts/TTNorms-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-Regular.woff2') format('woff2'),
  url('../fonts/TTNorms-Regular.woff') format('font'),
  url('../fonts/TTNorms-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms';
  src: url('../fonts/TTNorms-BlackItalic.eot'),
  url('../fonts/TTNorms-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-BlackItalic.woff2') format('woff2'),
  url('../fonts/TTNorms-BlackItalic.woff') format('font'),
  url('../fonts/TTNorms-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'TTNorms Black';
  src: url('../fonts/TTNorms-Black.eot'),
  url('../fonts/TTNorms-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTNorms-Black.woff2') format('woff2'),
  url('../fonts/TTNorms-Black.woff') format('font'),
  url('../fonts/TTNorms-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

:root {
  --art-dark: #171717;
  --art-dark-night: #050436;
  --art-default-dark: #303030;
  --art-default: #4F4F4F;
  --art-default-light: #6E6E6E;
  --art-default-extra: #9E9E9E;
  --art-default-super: #C2C2C2;
  --art-dark-white: #D3D3D3;
  --art-light: #F3F3F3;
  --art-light-white: #FAFAFA;
  --art-back-ground: #F8F8F8;
  --art-main-gray: #E0E5F1;
  --art-active-dark: #232095;
  --art-active: #2B28CB;
  --art-active-light: #2f2cee;
  --art-active-extra: #3330ff;
  --art-rose: #FF007A;
  --art-info: #378bc0;
  --art-error: #AD1313;
  --art-success: #53B43B;
  --art-light-success: #e0fddc;
  --art-success-dark: #034200;
  --art-warning: #F6532D;
  --art-warning-light: #ffdec2;
  --art-tg: #1BC7FD;
  --art-wa: #5BE070;
  --art-tg-dark: #147493;
  --art-wa-dark: #3b8c49;
}

* {
  -webkit-overflow-scrolling: touch;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: TTNorms, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-variant-ligatures: none !important;
  color: var(--art-dark) !important;
  background-color: var(--art-back-ground);
  min-width: 300px;
}

html,
body,
#app {
  height: 100%;
  scroll-behavior: auto !important;
}

.search-open {
  overflow: hidden;
}

.search-open #content,
.search-open #footer {
  filter: blur(3px);
  -ms-filter:  blur(3px);
  -webkit-filter: blur(3px);
}

video {
  background: var(--art-dark);
}

.frame-control iframe,
.frame-control video {
  width: 100% !important;
  height: 600px !important;
}

.vjs-fullscreen video,
.vjs-fullscreen frame {
  height: 100% !important;
}

@media screen and (max-width: 1200px) {
  .frame-control iframe,
  .frame-control video {
    width: 100% !important;
    height: 550px !important;
  }
}
@media screen and (max-width: 1070px) {
  .frame-control iframe,
  .frame-control video {
    height: 500px !important;
  }
}
@media screen and (max-width: 910px) {
  .frame-control iframe,
  .frame-control video {
    height: 440px !important;
  }
}
@media screen and (max-width: 812px) {
  .frame-control iframe,
  .frame-control video {
    height: 400px !important;
  }
}
@media screen and (max-width: 710px) {
  .frame-control iframe,
  .frame-control video {
    height: 350px !important;
  }
}
@media screen and (max-width: 600px) {
  .frame-control iframe,
  .frame-control video {
    height: 315px !important;
  }
}
@media screen and (max-width: 500px) {
  .frame-control iframe,
  .frame-control video {
    height: 270px !important;
  }
}
@media screen and (max-width: 430px) {
  .frame-control iframe,
  .frame-control video {
    height: 230px !important;
  }
}
@media screen and (max-width: 380px) {
  .frame-control iframe,
  .frame-control video {
    height: 200px !important;
  }
}

.menu-open {
  overflow: hidden;
  background-color: #ffffff;
}

.menu-open #app {
  overflow: hidden;
}

.menu-open #content {
  opacity: 0;
}

[role='button'] {
  cursor: auto;
}

#app {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border: 0 solid #000;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;
}

:not(:root):fullscreen::backdrop {
  background: rgba(5, 4, 54, 0.95) !important;
  z-index: 1100;
}

.invalid-feedback {
  margin-top: 1.25rem;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  #app {
    -webkit-overflow-scrolling: touch;
  }
}

#top {
  flex: 1 0 auto;
}

#footer {
  flex: 0 0 auto;
}

a {
  text-decoration: none;
}

blockquote {
  margin: 0 1px 1rem;
  border-left: 2px solid #ddd;
  padding: 0 20px 0;
}

strong,
b {
  font-weight: bold;
}

input,
textarea,
input:hover,
input:focus,
textarea:hover,
textarea:focus,
button:active,
button:focus,
input[type='text']:focus,
input[type='text'] {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  outline-color: transparent !important;
  font-variant-ligatures: none !important;
}

[contenteditable='true']:focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

textarea {
  min-height: 70px;
}

::-webkit-input-placeholder {
  color: #b8bfcf !important;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #b8bfcf !important;
  font-weight: 300;
}

::placeholder {
  color: #b8bfcf !important;
  font-weight: 300;
}

.placeholder-dark::-webkit-input-placeholder {
  color: #000000 !important;
}

.placeholder-dark:-ms-input-placeholder {
  color: #000000 !important;
}

.placeholder-dark::placeholder {
  color: #000000 !important;
}

@media screen and (max-width: 500px) {
  ::-webkit-input-placeholder {
    white-space: pre-wrap;
    max-width: 250px;
  }

  :-ms-input-placeholder {
    white-space: pre-wrap;
    max-width: 250px;
  }

  ::placeholder {
    white-space: pre-wrap;
    max-width: 250px;
  }
}

.placeholder-centered {
  resize: none;
  width: 100%;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 4px;
}

.placeholder-centered::placeholder {
  transform: translateY(70%);
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: unset;
}

button,
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.8rem 1.2rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 10px;
  transition:
    color 150ms ease-in-out,
    background-color 150ms ease-in-out,
    border-color 15ms ease-in-out,
    box-shadow 15ms ease-in-out;
  font-variant-ligatures: none !important;
  -webkit-appearance: unset;
}

button:hover,
button:focus,
.btn:hover,
.btn:focus {
  transition:
    color 150ms ease-in-out,
    background-color 150ms ease-in-out,
    border-color 15ms ease-in-out,
    box-shadow 15ms ease-in-out;
}

button::-moz-focus-inner {
  border: 0 !important;
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}

input:-webkit-autofill {
  color: #ffffff !important;
}

button#close {
  position: fixed;
  right: 25px;
  top: 0;
  background-color: transparent;
  border: 0;
  z-index: 10000;
  font-size: 60px;
  font-weight: 100;
  color: #fff;
}

button:disabled {
  background-color: rgba(239, 239, 239, 0.3);
  color: rgba(16, 16, 16, 0.3);
  cursor: not-allowed;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #2b28cb;
  border-color: #2b28cb;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

a {
  color: #2b28cb;
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  outline-color: transparent !important;
}

.icon-loading,
.icon-play,
.icon-ban,
.icon-open-book,
.icon-comment,
.icon-copy,
.icon-edit,
.icon-favorite,
.icon-filter,
.icon-gear,
.icon-grid,
.icon-grid-cubes,
.icon-heart,
.icon-heart-empty,
.icon-info-circle,
.icon-layers,
.icon-lines,
.icon-loader,
.icon-location,
.icon-logout,
.icon-messages,
.icon-news,
.icon-notification,
.icon-paint,
.icon-pencil,
.icon-image,
.icon-play,
.icon-repost,
.icon-share,
.icon-market,
.icon-send,
.icon-star-empty,
.icon-times,
.icon-upload,
.icon-user,
.icon-user-plus,
.icon-users {
  content: '';
  background-repeat: no-repeat;
  width: 25px;
  background-size: 100%;
  display: inline-block;
}

.icon-users {
  background-image: url(../images/i/users.svg);
}
.icon-user {
  background-image: url(../images/i/user.svg);
}
.icon-upload {
  background-image: url(../images/i/upload.svg);
}
.icon-times {
  background-image: url(../images/i/times.svg);
}
.icon-star-empty {
  background-image: url(../images/i/star-empty.svg);
}
.icon-send {
  background-image: url(../images/i/send.svg);
}
.icon-search {
  background-image: url(../images/i/search.svg);
}
.icon-repost {
  background-image: url(../images/i/repost.svg);
}
.icon-pencil {
  background-image: url(../images/i/pencil.svg);
}
.icon-notification {
  background-image: url(../images/i/notification.svg);
}
.icon-news {
  background-image: url(../images/i/news.svg);
}
.icon-messages {
  background-image: url(../images/i/messages.svg);
}
.icon-logout {
  background-image: url(../images/i/logout.svg);
}
.icon-location {
  background-image: url(../images/i/location.svg);
}
.icon-loader {
  background-image: url(../images/i/loader.svg);
}
.icon-lines {
  background-image: url(../images/i/lines.svg);
}
.icon-layers {
  background-image: url(../images/i/layers.svg);
}
.icon-info-circle {
  background-image: url(../images/i/info-circle.svg);
}
.icon-heart-empty {
  background-image: url(../images/i/heart-empty.svg);
}
.icon-heart {
  background-image: url(../images/i/heart.svg);
}
.icon-gear {
  background-image: url(../images/i/gear.svg);
}
.icon-filter {
  background-image: url(../images/i/filter.svg);
}
.icon-favorite {
  background-image: url(../images/i/favourite.svg);
}
.icon-edit {
  background-image: url(../images/i/edit.svg);
}
.icon-copy {
  background-image: url(../images/i/copy.svg);
}
.icon-comment {
  background-image: url(../images/i/comment.svg);
}
.icon-open-book {
  background-image: url(../images/i/book-open.svg);
}
.icon-ban {
  background-image: url(../images/i/ban.svg);
}
.icon-share {
  background-image: url(../images/i/share.svg);
}
.icon-paint {
  background-image: url(../images/i/paint.svg);
}

.icon-loading {
  background-image: url(../images/i/loader.svg);
}

.icon-image {
  background-image: url(../images/i/picture.svg);
}

.icon-market {
  background-image: url(../images/i/shop.svg);
}

.icon-grid {
  background-image: url(../images/i/grid.svg);
}

.icon-grid-cubes {
  background-image: url(../images/i/grid-cubes.svg);
}

.icon-play {
  width: 21px;
  background-image: url(../images/i/play.svg);
}

.icon-user-plus {
  background-image: url(../images/i/user-plus.svg);
}

.info-svg {
  cursor: help;
}

.input-group-text {
  border-bottom: 1px solid #e0e5f1 !important;
}

.disabled.input-group {
  color: #8d8d8d;
  cursor: not-allowed;
}

.cropper-backdrop {
  z-index: 1050 !important;
}

.scroll-area {
  opacity: 0;
  min-height: 200vh;
  padding-bottom: 10rem;
}

.scroll-area.area-loading {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.tooltip {
  z-index: 1015 !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide.slick-active > div {
  display: flex;
  height: 100%;
}

.slick-track {
  margin-left: unset !important;
}

.gm-style .gm-style-iw-d {
  box-sizing: border-box;
  overflow: auto !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
  transform: translate(-50%, -100%);
  margin: 0 !important;
  border-radius: 5px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  max-width: 200px;
  padding: 15px;
  border: 1px solid #000;
  color: white;
  font-weight: 500;
  text-align: left;
  background-color: #000;
  border-radius: 0.25rem;
  opacity: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.content h3,
.ql-editor h3 {
  font-family: 'Garamond', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem !important;
}

.height-auto {
  height: auto !important;
  padding: 0 13px 0 0 !important;
}

.ql-editor h4,
.content h4 {
  font-size: 1.2rem !important;
}

@media screen and (max-width: 812px) {
  .ql-editor,
  .content {
    font-size: 0.85em !important;
    line-height: 18.2px;
  }

  .content h3,
  .ql-editor h3 {
    font-size: 1.2rem !important;
  }

  .ql-editor h4,
  .content h4 {
    font-weight: 500;
    font-size: 0.85em !important;
    line-height: 22px;
  }
}

.content .ql-align-center {
  text-align: center;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: white;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: white;
}

.input-group .input-group-prepend .input-group-text {
  margin-left: -20px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  padding: 0;
}

.close-alert {
  color: #fff;
  font-size: 42px;
  font-weight: 100;
  padding: 0 0 0 15px;
  line-height: 0.8;
  position: relative;
  z-index: 1001;
  cursor: pointer;
}

.question-h3 {
  margin-right: 0.5rem;
}

.form-control {
  font-size: 20px;
  border: 1px solid #e0e5f1;
  height: calc(1.5em + 0.75rem + 5px);
}

.form-control:focus {
  border: 2px solid #2b28cb;
}

.form-control.border-bottom {
  border: 0;
  border-radius: 0;
  min-height: 56px !important;
  border-bottom: 1px solid #e0e5f1;
}

.form-control.border-bottom:focus {
  border-bottom: 2px solid #2b28cb;
}

.no-resize::-webkit-resizer {
  border-width: 0;
  border-style: solid;
  border-color: transparent;
}

.form-control.bordered {
  border: 1px solid #e0e5f1;
  padding: 10px 45px;
  border-radius: 3px !important;
  height: calc(2.7em + 0.75rem + -10px);
  max-width: 365px;
  margin-left: 5px;
}

.form-control.has-primary {
  border: 1px solid #2b28cb;
  border-right: 0;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
  height: auto;
}

@media screen and (max-width: 1170px) {
  .form-control.bordered {
    max-width: 100%;
    width: 100%;
  }
}

.form-control.bordered:hover,
.form-control.bordered:focus {
  border: 1px solid #2b28cb;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

.has-error {
  border: 1px solid #a60000;
}

.input-group .dropdown-toggle {
  border: 1px solid #c5c6c6 !important;
  padding: 5px 15px;
  border-left: 0 !important;
}

.dropdown-toggle::after {
  margin-left: 0.555em;
  vertical-align: 0.155em;
  content: '';
  border: 2px solid #ffffff;
  transform: rotate(45deg);
  border-top: 0 solid transparent;
  border-left: 0 solid transparent;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.only-search .border-bottom {
  padding-left: 35px;
  margin-bottom: 20px;
}

.only-search .input-group-prepend {
  margin-right: -40px;
  top: 1px;
  position: relative;
  z-index: 100;
}

.only-search .input-group-prepend svg {
  margin-left: 10px;
}

.prepend {
  padding-left: 25px !important;
}

.prepend-item {
  position: relative;
  right: -19px;
  bottom: 1px;
  z-index: 100;
}

.text-primary {
  color: #2b28cb !important;
}

.text-dange {
  color: #ad1313 !important;
}

a:hover {
  color: #2b28cb;
  text-decoration: underline;
}

.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.form-check:not(.form-switch) {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check:not(.form-switch) input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form-check:not(.form-switch) .form-check-label {
  line-height: 3;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.form-check:not(.form-switch) .form-check-label:before {
  content: '';
  position: absolute;
  border-radius: 3px;
  top: 6px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #f3f3f3;
}

.form-check:not(.form-switch) .form-check-label:after {
  content: '';
  position: absolute;
  background-image: url(../images/icons/check.svg);
  background-repeat: no-repeat;
  background-size: contain;
  left: 7px;
  top: 10px;
  width: 15px;
  height: 15px;
}

.form-check:hover .form-check-label:before {
  background-color: #e0e5f1;
}

.form-check:not(.form-switch) input:checked ~ .form-check-label:before {
  background-color: #2b28cb;
}

.form-check:not(.form-switch) input:checked ~ .form-check-label:after {
  border-color: #ddd;
}

.form-check-input:checked {
  background-color: #2b28cb;
  border-color: #2b28cb;
}

.form-control-lg {
  border: 0;
  height: calc(2.7em + 0.75rem + 6px);
  background-color: white;
  padding: 1rem 0;
  color: #000;
  font-size: 20px;
  line-height: 1.7;
  border-radius: 0;
  border-bottom: 1px solid #e0e5f1;
}

textarea.form-control-lg {
  height: calc(2.7em + 0.75rem + 30px);
  background-color: white;
  padding: 20px;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid #e0e5f1;
}

.form-control-lg:focus,
.form-control-lg:hover {
  border: 0;
  border-bottom: 1px solid #2b28cb;
  background-color: #ffffff;
}

textarea.form-control-lg:focus,
textarea.form-control-lg:hover {
  border: 1px solid #2b28cb;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.7rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
}

#drop-btn.disabled {
  background: #737373 !important;
  cursor: not-allowed !important;
}

.disabled-eye {
  color: #e4e7ea;
  cursor: not-allowed;
}

:focus {
  outline-style: auto;
  outline-width: 0 !important;
  outline-color: transparent !important;
}

.outline-none:hover,
.outline-none:focus {
  border-color: #c5c6c6 !important;
}

.btn-xs {
  font-size: 0.8rem;
  line-height: 1.2rem;
  padding: 0.275rem 0.49rem;
}

.btn-md {
  font-size: 14px;
  line-height: 1.2rem;
  padding: 5px 7px;
}

.btn-sm {
  font-size: 1rem;
  line-height: 1.3rem;
  padding: 0.475rem 0.65rem;
}

.btn-sxs {
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 0.175rem 0.25rem;
}

.btn-xl {
  padding: 1.2rem 2rem;
  text-transform: none;
  font-size: 1rem;
}

.btn-form-append {
  border: 1px solid #c5c6c6;
  border-left: 0;
}

.btn-primary {
  background-color: #2b28cb;
  border-color: #2b28cb;
}

.btn-outline-primary {
  border-color: #2b28cb;
  color: #2b28cb;
}

.btn-outline-primary-link {
  border-color: #2b28cb;
  color: #2b28cb;
}

.btn-outline-primary-link:hover,
.btn-outline-primary-link:focus {
  background-color: #4d4af3;
  color: white;
}

.btn-outline-secondary {
  border-color: #cbd6f4;
  color: #2b28cb;
}

.btn-outline-danger {
  border-color: rgba(246, 83, 45, 0.24) !important;
  color: #f6532d;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #929292 !important;
  border-color: #929292 !important;
}

.btn-outline-dark {
  border-color: #cec9c9;
  color: #000000;
}

.btn-outline-black {
  border-color: #000000;
  color: #000000;
}

.btn-outline-black:hover,
.btn-outline-black:focus {
  border-color: #000000;
  background-color: #000000;
  color: white;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #2b28cb;
  border-color: #2b28cb;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #4d4af3;
  border-color: #4d4af3;
}

.rm-btn {
  padding: 0.9rem 0;
  border-radius: 0;
}

.active.btn-outline-primary {
  border-color: #4d4af3 !important;
  color: #4d4af3 !important;
  background-color: #fff !important;
}

.btn-12 {
  font-size: 12px;
  line-height: 1.3rem;
  padding: 0.475rem 1.5rem;
}

.btn-18 {
  font-size: 18px;
  line-height: 1.3rem;
  padding: 0.6rem 1.7rem;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.react-confirm-alert-overlay {
  z-index: 1500;
  background: rgba(5, 4, 54, 0.95) !important;
}

.check {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #e9edf5;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type='radio'] {
  display: none;
}

input[type='radio']:checked ~ .check:before {
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #2b28cb;
}

.btn.focus,
button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -23px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  list-style: none;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: #2b28cb;
}

/*  Confirm Alert window */
.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 1021;
  background: rgba(0, 0, 0, 0.56);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 200ms 200ms forwards;
  -moz-animation: react-confirm-alert-fadeIn 200ms 200ms forwards;
  -o-animation: react-confirm-alert-fadeIn 200ms 200ms forwards;
  animation: react-confirm-alert-fadeIn 200ms 200ms forwards;
}

.react-confirm-alert-body {
  box-shadow: none;
  width: 100%;
  max-width: 700px;
  color: #fff;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
}

.react-confirm-alert-body:before {
  display: flex;
  content: '';
  background-image: url(../images/alert-icon.svg);
  background-position: center;
  height: 100%;
  background-size: 7rem;
  min-height: 260px;
  background-repeat: no-repeat;
}

.alert-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

.alert-buttons button {
  border-color: white;
  padding: 12px 5px;
  color: white;
  font-size: 16px;
}

.react-confirm-alert-body h1 {
  color: #fff;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-button-group {
  justify-content: space-around;
  display: flex;
  margin-top: 50px;
}

.react-confirm-alert-button-group button {
  padding: 1rem 2rem;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 18px;
}

.react-confirm-alert-button-group button:hover,
.react-confirm-alert-button-group button:focus {
  background-color: #fff;
  color: #131240;
  -webkit-animation: all 0.3s linear;
  -moz-animation: all 0.3s linear;
  -o-animation: all 0.3s linear;
  animation: all 0.3s linear;
}

.update-btn {
  margin-right: 15px;
}

@media screen and (max-width: 812px) {
  .alert-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .alert-buttons button {
    width: 100%;
    padding: 8px 0;
  }

  .react-confirm-alert {
    padding: 10px 15px;
  }

  .react-confirm-alert-body:before {
    background-size: 3rem;
    min-height: 120px;
  }

  .react-confirm-alert-body {
    font-size: 1.6rem;
  }

  .react-confirm-alert-body h1 {
    font-size: 1.6rem;
  }

  .update-btn {
    margin-left: 15px;
    margin-right: 0;
  }

  .check {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  input[type='radio']:checked ~ .check:before {
    width: 12px;
    height: 12px;
  }

  .form-control-lg {
    font-size: 14px !important;
  }

  .form-control {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 500px) {
  .icon-loading,
  .icon-play,
  .icon-ban,
  .icon-open-book,
  .icon-comment,
  .icon-copy,
  .icon-edit,
  .icon-favorite,
  .icon-filter,
  .icon-gear,
  .icon-grid,
  .icon-grid-cubes,
  .icon-heart,
  .icon-heart-empty,
  .icon-info-circle,
  .icon-layers,
  .icon-lines,
  .icon-loader,
  .icon-location,
  .icon-logout,
  .icon-messages,
  .icon-news,
  .icon-notification,
  .icon-paint,
  .icon-pencil,
  .icon-image,
  .icon-repost,
  .icon-share,
  .icon-send,
  .icon-star-empty,
  .icon-times,
  .icon-upload,
  .icon-user,
  .icon-user-plus,
  .icon-users {
    width: 22px;
    height: 22px;
  }
  .icon-market {
    width: 22px;
    height: 26px;
  }
  .icon-play {
    width: 18px;
    height: 24px;
  }
  .update-btn {
    width: 94%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 420px) {
  .update-btn {
    width: 100%;
  }
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loader-animation {
  0% {
    width: 0;
  }
  49% {
    width: 100%;
    left: 0;
  }
  50% {
    left: 100%;
    width: 0;
  }
  100% {
    left: 0;
    width: 100%;
  }
}

.animate-fade-up {
  opacity: 0;
  bottom: -50px;
  position: relative;
}

.animate-fade-up.show {
  opacity: 1;
  bottom: 0;
  transition: all 600ms ease-out;
}

.modal-open #app {
  filter: blur(2px);
  -ms-filter: blur(2px);
  -webkit-filter: blur(2px);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.44);
  opacity: 0.95 !important;
}

.modal-content {
  position: relative;
  display: flex;
  margin-top: 30px;
  min-width: 250px;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  outline: 0;
}
.modal-body {
  position: static;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-cs {
  max-width: 1000px;
}

.modal-md {
  max-width: 700px;
}

.disabled-modal {
  position: relative;
}

.disabled-modal:before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hidden {
  display: none;
  visibility: hidden;
}

.visible-sm,
.visible-md {
  display: none;
  visibility: hidden;
}

.rounded {
  border-radius: 50% !important;
}

.loader {
  height: 5px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
}

.loader .bar {
  position: absolute;
  height: 1px;
  background-color: #2b28cb;
  animation-name: loader-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.check-box {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-check {
  border-radius: 4px !important;
  margin-right: 10px;
  padding: 0.7rem 0.8rem;
  color: #000;
  border: 1px solid #e0e5f1;
  pointer-events: auto;
  cursor: pointer;
}

.btn-check.btn-outline-primary.active {
  border: 1px solid #0d52ee !important;
  color: #0d52ee !important;
}

.border {
  border-radius: 5px;
  border: 1px solid #c5c6c6 !important;
}

.filepond--item-panel {
  background-color: #2321ad !important;
}

.filepond--item {
  top: 100px !important;
  box-shadow: 0 0 10px #00000030;
  border-radius: 5px;
}

.filepond--root .filepond--drop-label {
  min-height: 2.75em;
  max-width: 300px;
  margin: 0 auto;
}

.invalid {
  background: #dc3545;
  padding: 0 4px;
  font-size: 65%;
  border-radius: 1rem;
  line-height: 1.1;
  margin-top: 0.1rem;
  color: #ffffff;
  width: max-content;
}

.custom-control-label::before {
  position: absolute;
  top: inherit;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1.2rem;
  pointer-events: none;
  content: '';
  border: #95a4aa solid 1px;
  background: white;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #2b28cb;
  background: white;
}

.thumb-image {
  border: 0;
  border-radius: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  width: 200px;
  margin: 0 10px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(90vh - 80px);
  min-height: calc(90vh - 80px);
}

.thumb-image .image-gallery-thumbnail-inner {
  display: flex;
  max-height: 130px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.thumb-image .image-gallery-thumbnail-image {
  width: auto;
}

.thumb-image.active,
.thumb-image:focus,
.thumb-image:hover {
  border: 0;
  border-bottom: 2px solid #0d52ee;
}

.custom-switch .custom-control-label {
  margin-top: inherit;
  font-size: 85%;
  display: flex;
  align-items: center;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 4px);
  left: calc(-2.25rem + 3px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #2b28cb;
  transform: translateX(14px);
  top: 8px;
  width: 12px;
  height: 12px;
}

.ril__outer {
  background-color: rgba(2, 2, 31, 0.9) !important;
}

.custom-switch .custom-control-label::before {
  left: -2.2rem;
  width: 30px;
  height: 16px;
  pointer-events: all;
  border-radius: 1.6rem;
}

.date-range {
  display: flex;
  position: relative;
  align-items: center;
}

.date-range * {
  box-sizing: border-box;
}

.date-range-clear {
  position: relative;
  font-size: 30px;
  font-weight: 100;
  left: -18px;
  cursor: pointer;
  overflow: hidden;
  color: #000;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  margin: 0;
}

.date-range-clear:hover,
.date-range-clear:focus {
  text-decoration: none;
}

.date-range-input {
  position: relative;
  border: 0;
  height: calc(2.7em + 0.75rem + 6px);
  background-color: white;
  padding: 1rem 0;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 0;
  border-bottom: 1px solid #e0e5f1;
  width: 100%;
}

.date-range-input[disabled] {
  color: #ccc;
  background: #f7f7f7;
  cursor: not-allowed;
}

.date-range-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}

.date-range-panel * {
  box-sizing: border-box;
}

.date-range-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 1rem;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}

.date-range-panel-narrow {
  max-width: 113px;
}

.date-range-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
}

.date-range-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
}

.date-range-panel-input-invalid {
  border-color: red;
}

.date-range-panel-select {
  float: left;
  font-size: 16px;
  border: 1px solid #e9e9e9;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 170px;
  overflow-y: auto;
  position: relative;
}

.date-range-panel-select-active {
  overflow-y: auto;
}

.date-range-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}

.date-range-panel-select:last-child {
  border-right: 0;
}

.date-range-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}

.date-range-panel-select li {
  list-style: none;
  margin: 0;
  width: 100%;
  height: 24px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.date-range-panel-select li:hover {
  background: #edfaff;
}

li.date-range-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}

li.date-range-panel-select-option-disabled {
  color: #ccc;
}

li.date-range-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}

/*!
 * https://github.com/arqex/react-datetime
 */

.rdt {
  position: relative;
  border-bottom: 1px solid #e0e5f1;
}

.rdt .form-control-lg {
  border-bottom: 0;
}

.rdt:hover {
  border-bottom: 1px solid #2b28cb;
}

.rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  width: 30px;
  vertical-align: top;
  padding: 10px 0;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}

.rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

div[id] {
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.high-light {
  background: rgba(255, 223, 0, 0.31) !important;
  -webkit-transition: background-color 300ms linear, background 300ms linear;
  -moz-transition: background-color 300ms linear, background 300ms linear;
  -o-transition: background-color 300ms linear, background 300ms linear;
  transition: background-color 300ms linear, background 300ms linear;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

#wait i {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

#wait i:nth-child(2) {
  animation-delay: 0.2s;
}

#wait i:nth-child(3) {
  animation-delay: 0.4s;
}

/*GOOGLE ADDRESS CONTAINER*/
.pac-container {
  z-index: 1100 !important;
}

@media screen and (min-width: 960px) {
  html {
    margin-right: 0;
  }
}

@media screen and (max-width: 812px) {
  .visible-md {
    display: block !important;
    visibility: visible;
  }
  .responsive-btn {
    width: 100%;
  }

  .btn-md {
    font-size: 13px;
  }

  .only-search .input-group-prepend {
    padding: 0;
  }

  .only-search {
    margin-top: 0;
    margin-left: 4px;
  }

  .only-search .border-bottom {
    padding-left: 0;
    margin-bottom: 20px;
  }

  .only-search .input-group-append {
    margin-left: -20px;
    top: -10px;
    position: relative;
    z-index: 100;
  }

  .modal-xl {
    max-width: 100% !important;
    margin: 1.75rem auto;
  }

  select.form-control {
    height: auto;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(85vh - 80px);
    min-height: calc(85vh - 80px);
  }

  .thumb-image {
    width: 70px;
  }

  .thumb-image .image-gallery-thumbnail-inner {
    display: flex;
    max-height: 75px;
  }

  .alert {
    left: 0;
    top: 0;
  }

  .btn-12 {
    padding: 0.3rem 0.4rem;
  }

  .h1,
  h1 {
    font-size: 1.9rem;
  }
}

@media (min-width: 576px) {
  .visible-sm {
    display: block !important;
    visibility: visible;
  }
  .px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .modal-xl {
    max-width: 100%;
    margin: 1.75rem auto;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

@media screen and (max-width: 1024px) {
  .btn-xl {
    padding: 1rem 1.3rem;
  }
}

@media screen and (min-width: 1354px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1314px !important;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 1355px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 812px) {
  .separate-checkbox {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px 0;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
    padding: 0;
  }
}
